import React from 'react';
import styled from 'react-emotion';
import { decodeHTML } from '../../Theme/Utils';

const ChosenProductHeaderWrapper = styled('div')`
  margin: 0 auto;
  margin-top: 14px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.5rem;

  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 4.25rem;
    height: 7px;
    margin: 0.5rem auto 1rem;
    background: #000;
  }
`;

const ChosenProductHeader = styled('h2')`
  font-size: ${({ theme }) => theme.typography.h2};
  margin-bottom: 0.75rem;
  font-weight: bold;
`;

const ChosenProductHeaderModule = ({ header }) => {
  return (
    <ChosenProductHeaderWrapper>
      {header && (
        <ChosenProductHeader>{decodeHTML(header)}</ChosenProductHeader>
      )}
    </ChosenProductHeaderWrapper>
  );
};

export default ChosenProductHeaderModule;
