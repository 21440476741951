import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as CircleCheck } from '../../../svg/CircleCheck.svg';
import { decodeHTML } from '../../Theme/Utils';

const BulletinWrapper = styled('div')`
  width: 50%;
  margin-bottom: 3.75rem;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.below.md} {
    width: 48%;
    padding: 8px;
  }

  ${({ theme }) => theme.below.sm} {
    width: 100%;
  }
`;

const CheckCircle = styled(CircleCheck)`
  width: 28px;
  height: 28px;
  margin-right: 2rem;

  ${({ theme }) => theme.below.md} {
    flex: 1 0 auto;
  }
`;

const BullentinContent = styled('div')`
  width: 332px;

  ${({ theme }) => theme.below.sm} {
    width: 100%;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;

    ${({ theme }) => theme.below.sm} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const BulletinBoardPin = ({ header, text }) => {
  return (
    <BulletinWrapper>
      <CheckCircle />
      {header && text && (
        <BullentinContent>
          <h3>{decodeHTML(header)}</h3>
          <p>{decodeHTML(text)}</p>
        </BullentinContent>
      )}
    </BulletinWrapper>
  );
};

export default BulletinBoardPin;
