import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';
import { Above } from '@jetshop/ui/Breakpoints';
import { decodeHTML } from '../../Theme/Utils';

const FactCardWrapper = styled('div')`
  width: 50%;
  max-width: 608px;
  padding: 8px;

  ${({ theme }) => theme.below.md} {
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 56px;
  }
`;

const FactCardImageWrapper = styled('div')`
  position: relative;
`;

const FactCardImage = styled(Image)`
  width: 100%;
`;

const FactCardContent = styled('div')`
  padding: 32px 32px 0 8px;

  h2 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    line-height: 2rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .buttonLink {
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.colors.black};
    font-size: 1.125rem;
    padding: 16px 28px 12px;
    background: none;
    transition: ease-in-out 0.2s;

    &:hover {
      background: ${({ theme }) => theme.colors.wildSand};
    }
  }
`;

const FactCard = ({ image, header, text, buttonText, buttonLink }) => {
  return (
    <FactCardWrapper>
      <FactCardImageWrapper>
        {image && (
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <FactCardImage src={image} aspect={'61:40'} cover />
              ) : (
                <FactCardImage src={image} aspect={'23:18'} cover />
              )
            }
          </Above>
        )}
      </FactCardImageWrapper>
      <FactCardContent>
        {header && <h2>{decodeHTML(header)}</h2>}
        {text && <p>{decodeHTML(text)}</p>}
        {buttonLink && buttonText && (
          <Link className={'buttonLink'} to={buttonLink}>
            {buttonText}
          </Link>
        )}
      </FactCardContent>
    </FactCardWrapper>
  );
};

export default FactCard;
