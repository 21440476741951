import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';

const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const MainSection = props => {
  return <MainSectionWrapper>{props.children}</MainSectionWrapper>;
};

export default MainSection;
