import React from 'react';
import styled from 'react-emotion';
import { decodeHTML } from '../../Theme/Utils';

const BulletinHeaderWrapper = styled('div')`
  width: 100%;
  margin-bottom: 2.75rem;

  ${({ theme }) => theme.below.sm} {
    max-width: 228px;
    margin: 0 auto;
  }
`;

const BulletinHeaderContent = styled('div')`
  text-align: center;

  h2 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;

    ${({ theme }) => theme.below.sm} {
      margin-bottom: 3rem;
    }
  }
`;

const BulletinBoardHeader = ({ header, text }) => {
  return (
    <BulletinHeaderWrapper>
      <BulletinHeaderContent>
        {header && <h2>{decodeHTML(header)}</h2>}
        {text && <p>{decodeHTML(text)}</p>}
      </BulletinHeaderContent>
    </BulletinHeaderWrapper>
  );
};

export default BulletinBoardHeader;
