import React from 'react';
import styled from 'react-emotion';

const SmallTextWrapper = styled('span')`
  font-size: 10px;
  position: relative;
  bottom: 6px;
`;

const SmallText = ({ children }) => {
  return <SmallTextWrapper>{children}</SmallTextWrapper>;
};

export default SmallText;
