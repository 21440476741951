import React from 'react';
import styled from 'react-emotion';

const VideoWrapperOuter = styled('div')`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.below.sm} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const VideoWrapperInner = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  ${({ theme }) => theme.below.md} {
    margin: 2rem 0;
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Video = ({ src }) => {
  return !src ? null : (
    <VideoWrapperOuter>
      <VideoWrapperInner>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          controls={false}
          playsInline={true}
        >
          <source src={src} />
          <p>Your browser does not support the video element.</p>
        </video>
      </VideoWrapperInner>
    </VideoWrapperOuter>
  );
};

export default Video;
