import Hero from './Hero';
import MainSection from './MainSection';
import Row from './Row';
import ChosenProductHeaderModule from './ChosenProductHeaderModule';
import ChosenProduct from './ChosenProduct';
import ChosenProductsList from './ChosenProductsList';
import ProductInformation from './ProductInformation';
import ProductShowCase from './ProductShowCase';
import FactCard from './FactCard';
import BulletinBoardContainer from './BulletinBoardContainer';
import BulletinBoardHeader from './BulletinBoardHeader';
import BulletinBoardPin from './BulletinBoardPin';
import NavigatorPuff from './NavigatorPuff';
import InstagramHeader from './InstagramHeader';
import InstagramBlock from './InstagramBlock';
import InstagramFeed from './InstagramFeed';
import SmallText from './SmallText';
import BR from './BR';
import Video from './Video';

export default {
  BR,
  BulletinBoardContainer,
  BulletinBoardHeader,
  BulletinBoardPin,
  ChosenProduct,
  ChosenProductHeaderModule,
  ChosenProductsList,
  FactCard,
  Row,
  Hero,
  InstagramBlock,
  InstagramFeed,
  InstagramHeader,
  MainSection,
  NavigatorPuff,
  ProductInformation,
  ProductShowCase,
  SmallText,
  Video
};
