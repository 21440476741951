import React from 'react';
import styled from 'react-emotion';

const RowWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 -${({ theme }) => theme.space[1]};
  margin-bottom: 32px;

  ${({ theme }) => theme.below.md} {
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Row = props => {
  return <RowWrapper>{props.children}</RowWrapper>;
};

export default Row;
