import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import ChosenProductQuery from './ChosenProductQuery.gql';
import { ReactComponent as PowerbootsLogoColor } from '../../../svg/PowerbootsLogoColor.svg';
import { Price } from '@jetshop/ui/Price';
import { decodeHTML } from '../../Theme/Utils';
import { Name, SubName } from '../../Theme/GridProduct';

const StyledPrice = styled(Price)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  transition: color ease-in-out 0.1s;
  ${({ theme }) => theme.below.md} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${Price.New} {
    display: inline-block;
    order: 2;
    margin-left: 1rem;
    ${({ theme }) => theme.below.md} {
      display: block;
      margin-left: none;
      margin-top: 0.75rem;
    }
  }
  ${Price.Old} {
    display: inline-block;
    order: 1;
    ${({ theme }) => theme.below.md} {
      margin-top: 0.5rem;
      display: block;
    }
  }
`;

const ChosenProductOuter = styled('div')`
  padding: 72px;
  max-width: 400px;
  position: relative;

  ${({ theme }) => theme.below.sm} {
    padding: 48px;
    max-width: 245px;
  }
`;

const ChosenProductLogo = styled(PowerbootsLogoColor)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 77px;
  height: 52px;

  ${({ theme }) => theme.below.sm} {
    top: 16px;
    right: 16px;
    width: 42px;
    height: 28px;
  }
`;

const ChosenProductInner = styled('div')`
  width: 200px;

  ${({ theme }) => theme.below.md} {
    width: 152px;
  }
`;

const ChosenProductImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const ChosenProductContent = styled('div')`
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 8px 12px;
  text-align: left;
  transition: top ease-in-out 0.1s;

  p {
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  span {
    font-weight: 700;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    transition: top ease-in-out 0.1s;
    background: ${({ theme }) => theme.colors.themeBlack};
    z-index: -1;
    ${({ theme }) => theme.below.md} {
      display: none;
    }
  }
`;

const ChosenProductWrapper = styled('div')`
  ${({ theme }) => theme.below.sm} {
    margin-right: 12px;
  }
  ${({ theme }) => theme.above.md} {
    &:hover {
      ${ChosenProductContent} {
        &:before {
          top: 0;
        }
        p,
        ${StyledPrice} {
          color: ${({ theme }) => theme.colors.themeWhite} !important;
        }
      }
    }
  }
`;

const ChosenProduct = ({ articleNumber }) => {
  return (
    <>
      {articleNumber && (
        <Query
          query={ChosenProductQuery}
          variables={{ articleNumber: articleNumber }}
        >
          {({ data }) => {
            return (
              <>
                {data && data.product && data.product ? (
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={data.product.primaryRoute.path}
                  >
                    <ChosenProductWrapper>
                      <ChosenProductOuter>
                        <ChosenProductLogo />
                        <ChosenProductInner key={data.product.id}>
                          {data.product.images && (
                            <>
                              <ChosenProductImage
                                src={data.product.images[0].url}
                                aspect={'20:21'}
                              />
                            </>
                          )}
                        </ChosenProductInner>
                      </ChosenProductOuter>
                      <ChosenProductContent>
                        {data.product.name && (
                          <Name>{decodeHTML(data.product.name)}</Name>
                        )}
                        {data.product.subName && (
                          <SubName>{decodeHTML(data.product.subName)}</SubName>
                        )}
                        <StyledPrice
                          price={data.product.price}
                          recommendedPrice={data.product.recommendedPrice}
                          previousPrice={data.product.previousPrice}
                        />
                      </ChosenProductContent>
                    </ChosenProductWrapper>
                  </Link>
                ) : null}
              </>
            );
          }}
        </Query>
      )}
    </>
  );
};

export default ChosenProduct;
