import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import { decodeHTML } from '../../Theme/Utils';

const ProductShowCaseWrapper = styled('div')`
  width: 100%;
  display: flex;

  ${({ theme }) => theme.below.md} {
    flex-direction: column;
  }
`;

const ProductShowCaseImageContainer = styled('div')`
  width: 60%;

  ${({ theme }) => theme.below.md} {
    width: 100%;
  }
`;

const ProductShowCaseImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled('div')`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.below.md} {
    width: 100%;
    padding: 0 16px;
    margin: 32px 0 48px;
  }
`;

const ContentImageContainerOuter = styled('div')`
  margin-bottom: 32px;
`;

const ContentImageContainerInner = styled('div')`
  max-width: 250px;
  margin: 0 auto;

  ${({ theme }) => theme.below.md} {
    margin: 0;
  }

  ${({ theme }) => theme.below.sm} {
    margin: 0 auto;
  }
`;

const ContentImage = styled(Image)``;

const TextContainer = styled('div')`
  max-width: 392px;
  text-align: center;
  margin: 0 auto;

  ${({ theme }) => theme.below.md} {
    max-width: 100%;
    margin: 0;
    text-align: left;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`;

const ProductShowCase = ({ imageLeft, imageRight, header, text }) => {
  return (
    <ProductShowCaseWrapper>
      <ProductShowCaseImageContainer>
        {imageLeft && (
          <ProductShowCaseImage src={imageLeft} aspect={'73:52'} cover />
        )}
      </ProductShowCaseImageContainer>
      <ContentContainer>
        <ContentImageContainerOuter>
          <ContentImageContainerInner>
            {imageRight && <ContentImage src={imageRight} aspect={'5:6'} />}
          </ContentImageContainerInner>
        </ContentImageContainerOuter>
        <TextContainer>
          {header && <h2>{decodeHTML(header)}</h2>}
          {text && <p>{decodeHTML(text)}</p>}
        </TextContainer>
      </ContentContainer>
    </ProductShowCaseWrapper>
  );
};

export default ProductShowCase;
