import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';

const InstagramBlockContainer = styled('div')`
  width: 25%;
  padding: 0 10px 20px;
  position: relative;

  ${({ theme }) => theme.below.md} {
    width: 50%;
    flex: 1 1 50%;
    position: relative;
    padding: 0 8px 16px 8px;
  }
`;

const InstagramBlockContainerInner = styled('div')`
  width: 100%;
  height: 100%;
`;

const InstagramImage = styled(Image)`
  width: 100%;
  height: 100%;
  z-index: 1;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.colors.black};
    opacity: 0.2;
    z-index: 2;
    transition: opacity ease-in-out 0.2s;
    overflow: hidden;
  }

  &:hover {
    &:before {
      opacity: 0.05;
      z-index: 1;
    }
  }
`;

const InstagramBlock = ({ instagramLink, image }) => {
  return (
    <InstagramBlockContainer>
      <InstagramBlockContainerInner>
        {instagramLink && image ? (
          <a target="_blank" href={instagramLink} rel="noopener noreferrer">
            {image && <InstagramImage src={image} cover />}
          </a>
        ) : (
          <>{image && <InstagramImage src={image} cover />}</>
        )}
      </InstagramBlockContainerInner>
    </InstagramBlockContainer>
  );
};

export default InstagramBlock;
