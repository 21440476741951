import Analytics from '@jetshop/core/analytics/Analytics';
import { trackPage } from '@jetshop/core/analytics/tracking';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import startPageQuery from './StartPageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import ContentShortcodes from './StartpageComponents/ContentShortcodes';
import MaxWidth from '../Layout/MaxWidth';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartPage = ({ location: { pathname }, startPageId }) => {
  const stripTags = str => str.replace(/<[^>]*>?/gm, '');

  return (
    <Fragment>
      <Analytics
        track={trackPage({
          title: 'Lindström & Nilsson',
          pathname
        })}
      />
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result =>
            result.data.startPage ? (
              <>
                <Shortcodes
                  content={stripTags(result.data.startPage.content)}
                  components={ContentShortcodes}
                />
              </>
            ) : null
          }
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
