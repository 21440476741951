import React from 'react';
import styled from 'react-emotion';
import { decodeHTML } from '../../Theme/Utils';

const InstagramheaderWrapper = styled('div')`
  width: 100%;
  margin: 0 auto;
  margin-top: 14px;
  text-align: center;
  line-height: 1.5rem;

  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 4.25rem;
    height: 7px;
    margin: 1.25rem auto 1.5rem;
    background: #000;

    ${({ theme }) => theme.below.md} {
      margin-bottom: 2.5rem;
    }
  }
`;

const InstagramContent = styled('div')`
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0.25rem;
  }

  a {
    text-decoration: none;
  }
`;

const InstagramHeader = ({ headerLink, header, text }) => {
  return (
    <InstagramheaderWrapper>
      {headerLink && header && (
        <InstagramContent>
          <h2>
            <a href={headerLink} target="_blank" rel="noopener noreferrer">
              {decodeHTML(header)}
            </a>
          </h2>
          {text && (
            <p>
              <a href={headerLink} target="_blank" rel="noopener noreferrer">
                {decodeHTML(text)}
              </a>
            </p>
          )}
        </InstagramContent>
      )}
    </InstagramheaderWrapper>
  );
};

export default InstagramHeader;
