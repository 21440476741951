import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import { decodeHTML } from '../../Theme/Utils';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import ContentShortcodes from './ContentShortcodes';

const ProductInformationWrapper = styled('div')`
  width: 100%;
  padding: 58px;
  background: ${({ theme }) => theme.colors.themeGrey};
  margin-bottom: 32px;

  ${({ theme }) => theme.below.md} {
    padding: 2rem 2rem 3rem;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.below.sm} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ProductInformationInner = styled('div')`
  display: flex;

  ${({ theme }) => theme.below.md} {
    flex-direction: column;
  }
`;

const ProductInformationImageWrapper = styled('div')`
  width: 50%;

  ${({ theme }) => theme.below.md} {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const ProductInformationImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const ProductInformationContent = styled('div')`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  ${({ theme }) => theme.below.md} {
    width: 100%;
    padding: 0;
  }
`;

const ProductInformationContentInner = styled('div')`
  display: block;
  font-size: 1.25rem;
  line-height: 2rem;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  ${({ theme }) => theme.below.md} {
    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const ProductInformation = ({ image, header, text }) => {
  return (
    <ProductInformationWrapper>
      <ProductInformationInner>
        <ProductInformationImageWrapper>
          {image && (
            <ProductInformationImage src={image} aspect={'31:23'} cover />
          )}
        </ProductInformationImageWrapper>
        <ProductInformationContent>
          <ProductInformationContentInner>
            {header && <h2>{decodeHTML(header)}</h2>}
            {text && (
              <Shortcodes content={text} components={ContentShortcodes} />
            )}
          </ProductInformationContentInner>
        </ProductInformationContent>
      </ProductInformationInner>
    </ProductInformationWrapper>
  );
};

export default ProductInformation;
