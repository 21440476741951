import React from 'react';
import styled from 'react-emotion';

const InstagramFeedWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.below.md} {
    padding: 32px 8px 0;
  }
`;

const InstagramFeed = ({ children }) => {
  return <InstagramFeedWrapper>{children}</InstagramFeedWrapper>;
};

export default InstagramFeed;
