import React from 'react';
import styled from 'react-emotion';

const ChosenProductsWrapperOuter = styled('div')`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

const ChosenProductsWrapper = styled('div')`
  width: 100%;
  max-width: 936px;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 48px;

  ${({ theme }) => theme.below.sm} {
    justify-content: flex-start;
    padding-left: 20px;
    padding-bottom: 32px;
  }
`;

const ChosenProductsList = ({ children }) => {
  return (
    <ChosenProductsWrapperOuter>
      <ChosenProductsWrapper>{children}</ChosenProductsWrapper>
    </ChosenProductsWrapperOuter>
  );
};

export default ChosenProductsList;
