import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';

const BulletinBoardWrapper = styled('div')`
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-top: 5rem;

  ${({ theme }) => theme.below.sm} {
    padding-top: 2.5rem;
  }
`;

const BulletinImage = styled(Image)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const BulletinWrapperInner = styled('div')`
  max-width: 992px;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.below.md} {
    justify-content: center;
  }

  ${({ theme }) => theme.below.sm} {
    padding: 0 1rem;
    flex-direction: column;
  }
`;

const BulletinBoardContainer = ({ backgroundImage, children }) => {
  return (
    <BulletinBoardWrapper>
      {backgroundImage && <BulletinImage src={backgroundImage} cover />}
      <BulletinWrapperInner>{children}</BulletinWrapperInner>
    </BulletinBoardWrapper>
  );
};

export default BulletinBoardContainer;
