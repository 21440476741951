import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth from '../../Layout/MaxWidth';
import { Above } from '@jetshop/ui/Breakpoints';
import { decodeHTML } from '../../Theme/Utils';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import ContentShortcodes from './ContentShortcodes';

const HeroContent = styled('div')`
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  h1 {
    max-width: 75%;
    position: relative;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 3rem;
    line-height: 1.25;
    ${({ theme }) => theme.below.md} {
      max-width: none;
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    &:after {
      content: '';
      width: 192px;
      height: 7px;
      position: absolute;
      bottom: -2rem;
      left: 0;
      background: #fff;
    }
  }
  p {
    max-width: 460px;
  }
  a {
    display: inline-block;
    min-width: 296px;
    padding: 0.8em 0;
    margin-top: 0.5rem;
    background: #fff;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    transition: background ease-in-out 0.2s;
    &:hover {
      background: #ededed;
    }
  }
`;

const HeroImage = styled(Image)`
  /* height: 560px !important; */
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.below.md} {
    /* height: 440px !important; */
    /* padding-top: 0 !important; */
    // background-position: right -160px bottom -50px !important;
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  /*
  &:before {
    top: 0;
    background: -moz-linear-gradient(
      top,
      rgba(34, 34, 34, 0.8) 0%,
      rgba(255, 255, 255, 0) 68%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(34, 34, 34, 0.8) 0%,
      rgba(255, 255, 255, 0) 68%
    );
    background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 0.8) 0%,
      rgba(255, 255, 255, 0) 68%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc222222', endColorstr='#00ffffff',GradientType=0 );
  }
  &:after {
    bottom: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 32%,
      rgba(34, 34, 34, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 32%,
      rgba(34, 34, 34, 0.8) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 32%,
      rgba(34, 34, 34, 0.8) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#cc222222',GradientType=0 );
  }
  */

  &.gradient {
    ${({ theme }) => theme.above.md} {
      &:before {
        top: 0;
        background: -moz-linear-gradient(
          top,
          rgba(34, 34, 34, 0.8) 0%,
          rgba(255, 255, 255, 0) 68%
        );
        background: -webkit-linear-gradient(
          top,
          rgba(34, 34, 34, 0.8) 0%,
          rgba(255, 255, 255, 0) 68%
        );
        background: linear-gradient(
          to bottom,
          rgba(34, 34, 34, 0.8) 0%,
          rgba(255, 255, 255, 0) 68%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc222222', endColorstr='#00ffffff',GradientType=0 );
      }
    }

    &:after {
      bottom: 0;
      height: 100%;
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 55%
      );
      background: -webkit-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 55%
      );
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 55%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
  }

  &.smallImage {
    ${({ theme }) => theme.below.sm} {
      height: 160px !important;
    }
  }
`;

const HeroMaxWidth = styled(MaxWidth)``;

const StartpageHero = styled('div')`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space[4]};

  > ${HeroMaxWidth} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${({ theme }) => theme.space[8]};
    margin: 0 auto;
    z-index: 3;
    ${({ theme }) => theme.below.md} {
      bottom: ${({ theme }) => theme.space[2]};
    }
  }
`;

const Hero = ({
  image,
  header,
  text,
  gradient,
  aspect,
  mobileAspect,
  smallImage
}) => {
  return (
    <StartpageHero>
      {image && (
        <Above breakpoint="md">
          {matches => (
            <HeroImage
              quality={'100'}
              src={image}
              cover
              aspect={
                matches
                  ? aspect
                    ? aspect
                    : '171:61'
                  : mobileAspect
                  ? mobileAspect
                  : '40:49'
              }
              sizes={matches ? undefined : ['40rem']}
              className={
                gradient ? 'gradient' : smallImage ? 'smallImage' : null
              }
            />
          )}
        </Above>
      )}

      {header && text && (
        <HeroMaxWidth>
          <HeroContent>
            <h1>{decodeHTML(header)}</h1>
            <Shortcodes content={text} components={ContentShortcodes} />
          </HeroContent>
        </HeroMaxWidth>
      )}
    </StartpageHero>
  );
};

export default Hero;
